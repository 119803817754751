import React from 'react';

const CalendarHeader = ({ onNext, onBack, dateDisplay, nav}) => {
  return(
    <div className="header">
      <div id="monthDisplay">{dateDisplay}</div>
      <div>
        {nav !== 0 && 
        <button className="button" onClick={onBack} id="backButton">Atras</button>
        }
        <button className="button" onClick={onNext} id="nextButton">Siguiente</button>
      </div>
    </div>
  );
};


export default CalendarHeader;
