import React, {useState} from 'react';
import App from "../../../Calendario/App/App";

const Cita = ({navigation, setHoraCita, setFechaCita, fecha_cita, hora_cita}) => {

    return (
        <>
        <App navigation={navigation} setHoraCita={setHoraCita} setFechaCita={setFechaCita} fecha_cita={fecha_cita} hora_cita={hora_cita}/>
        </>
    );
};

export default Cita;
