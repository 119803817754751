import React, {useState} from 'react';
import Agenda from './Consultoria/Agenda/Agenda';
import Consultoria from './Consultoria/Consultoria';

const Section = () => {

    const [tipo, setTipo] = useState(false);
    const [click, setClick] = useState(false);

    return (
        <>
        {click === false ? <Consultoria setClick={setClick} setTipo={setTipo}/> : <Agenda tipo={tipo}/>}
        </>
    );
};

export default Section;
