import React from 'react';
import MultiForm from '../formultario/MultiForm';
import "./Agenda.css";

const Agenda = ({tipo}) => {
    return (
        <>
        <div className="agenda-title">Agende {tipo === true ? 'Cita Legal' : 'Cita Contable'}</div>
        <div className="agenda-form">
            <div className="agenda-forms">
                <MultiForm tipo={tipo}/>
            </div>
        </div>
        </>
    );
};

export default Agenda;