import React from 'react';

export const Day = ({ day, onClick, disabled, weekend, valid, i, green}) => {
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''} ${!disabled ? 'disable': ''} ${weekend === 6 || weekend === 0 ? 'red': ''} ${valid ? 'red' : ''} ${i<day.value ? 'opacity': ''} ${green}`;
  return (
    <div onClick={onClick} className={className}>
      {day.value === 'padding' ? '' : day.value}
      {day.event && <div className='event'>{day.event.title}</div>}
    </div>
  );
};
