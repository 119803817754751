import React from 'react';
import {Label, GrupoInput, IconoValidacion, LeyendaError, Input} from '../Form/Formularios';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

const ComponentInput = ({tipo,label, placeholder, name, leyendaError, expresionRegular, estado, setEstado, funcion}) => {


    const onChange = (e) => {
        setEstado({...estado, campo: e.target.value});
    }

    const validacion = (e) => {
        if(expresionRegular){
            if(expresionRegular.test(estado.campo)){
                setEstado({...estado, valido:'true'});
            }else{
                setEstado({...estado, valido:'false'});
            }
        }

        if(funcion){
            funcion();
        }

    }

    return (
        <div>
            <Label htmlFor={name} valido={estado.valido}>{label}</Label>
            <GrupoInput>
                <Input
                    type={tipo}
                    placeholder={placeholder}
                    id={name}
                    name={name}
                    value={estado.campo}
                    onChange={onChange}
                    onKeyUp={validacion}
                    onBlur={validacion}
                    valido={estado.valido}
                />
                <IconoValidacion
                    icon={estado.valido === 'true' ? faCheckCircle : faTimesCircle}
                    valido={estado.valido}/>
            </GrupoInput>
            <LeyendaError valido={estado.valido}>{leyendaError}</LeyendaError>
        </div>
    );
};

export default ComponentInput;
