import React, {useState} from 'react';
import {useStep} from 'react-hooks-helper';
import Contacto from "./stepForm/Contacto";
import Cita from "./stepForm/Cita";
import Revisar from "./stepForm/Revisar";


const steps = [
    {id: 'contacto'},
    {id: 'cita'},
    {id: 'revisar'},

]


const MultiForm = ({tipo}) => {

    //const[formData,setFormData] = useForm(defaultData);
    const [nombre_persona,setNombrePersona] = useState({campo: '', valido: null});
    const [numero_persona,setNumeroPersona] = useState({campo: '', valido: null});
    const [correo_persona,setCorreoPersona] = useState({campo: '', valido: null});

    const [fecha_cita, setFechaCita] = useState('');
    const [hora_cita, setHoraCita] = useState('');


    const {step, navigation} = useStep({
    steps,
    initialStep: 0,
    })

    const props = {navigation, nombre_persona, setNombrePersona, numero_persona, setNumeroPersona, correo_persona, setCorreoPersona, hora_cita, setHoraCita, fecha_cita, setFechaCita, tipo}

    switch(step.id){
        case "contacto":
            return <Contacto {...props}/>;
        case "cita":
            return <Cita {...props}/>;
        case "revisar":
            return <Revisar {...props}/>;
    }

    return (
        <h1>holi</h1>
    );
};

export default MultiForm;
