import React, { useState } from 'react';

const ValidacionContacto = ({setValido}) => {

  return(
    <>
      <div id="newEventModal">
        <h2 className="error">Ingresa primero los datos</h2>

        <button
          onClick={() => setValido(false)}
          id="cancelButton">Cerrar</button>
      </div>

      //<div id="modalBackDrop"></div>
    </>
  );
};

export default ValidacionContacto;
