import React from 'react';
import {Boton, ContenedorBotonCentrado} from '../../Form/Formularios';
import "./Spinner.css";
const Enviado = ({mensaje, cerrar}) => {
    return (
        <>
        <div className="enviado-container">
            <span>{mensaje}</span>
            <ContenedorBotonCentrado>
            <Boton onClick={() => cerrar()}>Terminar</Boton>
            </ContenedorBotonCentrado>
        </div>
        <div className="enviado-modal"></div>
        </>
    );
};

export default Enviado;     