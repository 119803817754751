import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {Boton, ContenedorBotonCentrado} from '../../../Form/Formularios';
import Spinner from "../../Enviar/Spinner";
import Api from "../../../../api/Api";
import Enviado from '../../Enviar/Enviado';
import moment from 'moment';
import 'moment/locale/es';

const Revisar = ({navigation, nombre_persona, numero_persona, correo_persona, fecha_cita, hora_cita, tipo}) => {

    const {go} = navigation;
    const [loading, setLoading] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const [mensaje, setMensaje] = useState();
    const [f, setF] = useState();
    const [h, setH] = useState();

    const enviarCita = () => {
        setLoading(true);
        const cita = {
            tipo_cita: tipo,
            nombre_persona : nombre_persona.campo,
            numero_persona : numero_persona.campo,
            correo_persona : correo_persona.campo,
            fecha_cita,
            hora_cita,
        }

        console.log("enviado");

        Api.enviarCita(cita)
            .then(res =>{
                console.log(res)
                setLoading(false);
                setMensaje(res.data.data);
                setEnviado(true);
            }).catch(error => {
                console.log(error)
            })

    }

    useEffect(()=>{

        let arrayCadenas = fecha_cita.split('-');
        var m = moment(arrayCadenas[2] +"-"+arrayCadenas[1]+"-"+arrayCadenas[0] +" "+ hora_cita);
        m.locale('es');
        setF(m.format('dddd') +" "+  m.format('LL'));
        console.log(m.format('LT'));
        setH(m.format('LT'));

    },[]);

    const cerrarModal = () => {
        setEnviado(false);
        window.location.href = 'http://mlsc.mx/';
    }

    return (
        <>
        <div className="revisar-titulo">Revisa tu Información</div>
        <div className="revisar-contacto">
            <div className="revisar-contacto-titulo">Información de Contacto
            <FontAwesomeIcon icon={faEdit}className="editar" onClick={() => go('contacto')} /></div>
        </div>
        <div className="contacto-item">Nombre: <span>{nombre_persona.campo}</span></div>
        <div className="contacto-item">Correo: <span>{correo_persona.campo}</span></div>
        <div className="contacto-item">Número: <span>{numero_persona.campo}</span></div>
        <div className="revisar-cita">
            <div className="revisar-contacto-titulo">Información de Cita
            <FontAwesomeIcon icon={faEdit}className="editar" onClick={() => go('cita')} /></div>
        </div>
        <div className="cita-item">Fecha de la cita</div>
        <span className="cita-span">{f}</span>
        <div className="cita-item">Hora de la cita</div>
        <span className="cita-span">{h}</span>
        <div className="cita-item">Tipo de cita</div>
        <span className="cita-span">{tipo? 'Legal' : 'Contable'}</span>
        <ContenedorBotonCentrado>
            <Boton onClick={enviarCita}>Enviar</Boton>
        </ContenedorBotonCentrado>
        {loading && <Spinner/>}
        {enviado && <Enviado mensaje={mensaje} cerrar={cerrarModal}/>}
        </>
    );
};

export default Revisar;
