import React, { useEffect, useState } from 'react';

export const useDate = (nav) => {
  const [dateDisplay, setDateDisplay] = useState('');
  const [days, setDays] = useState([]);
  const [valido, setValid] = useState();

  useEffect(() => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dt = new Date();

    if (nav !== 0) {
      dt.setMonth(new Date().getMonth() + nav);
    }

    //dt.setDate(29);

    const day = dt.getDate();
    const month = dt.getMonth();
    const year = dt.getFullYear();

    //console.log("---- " + dt.getDay() === 6  )

    const firstDayOfMonth = new Date(year, month, 1);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dateString = firstDayOfMonth.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    let validDays = 0;

    if(dt.getDay() === 6){
      validDays = 2;
    }else if(dt.getDay() === 0){
      validDays = 1;
    }else if(dt.getDay() === 5){
      validDays =3;
    }


    const rest = (daysInMonth - day);


    if(nav === 0){
      setValid(validDays - rest);
    }

    //console.log(nav);

    //console.log(valido);
    //console.log(validDays);

    setDateDisplay(`${dt.toLocaleDateString(undefined, { month: 'long' })} ${year}`);
    const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);

    const daysArr = [];

    for (let i = 1; i <= paddingDays + daysInMonth; i++) {
      const dayString = `${month + 1}/${i - paddingDays}/${year}`;
	const dateBack = `${i-paddingDays}-${month+1}-${year}`;

    const dateFake = new Date();

    dateFake.setFullYear(year);
    dateFake.setMonth(month);
    if(i - paddingDays > 0){
    dateFake.setDate(i - paddingDays);
    }

      if (i > paddingDays) {

        //console.log(valido);
        //console.log(i - paddingDays);
        //console.log(nav === 1 && i <= valido);
        //console.log((i- paddingDays) > day && (i- paddingDays < (day+ validDays + 1)))

        daysArr.push({
          value: i - paddingDays,
          valuetwo: day,
          i:i-paddingDays,
          isCurrentDay: i - paddingDays === day && nav === 0,
          date: dayString,
	        fecha: dateBack,
          dia : i - paddingDays >= day && nav === 0  ? true : false,
          weekend : dateFake.getDay(),
          validNavCero: (i- paddingDays) > day && (i- paddingDays) < (day + validDays + 1) && nav === 0  ? true : false,
          validNavUno : nav === 1 && (i - paddingDays) <= valido ? true : false
        });
      } else {

        //console.log(valido);
        //console.log(i- paddingDays);

        daysArr.push({
          value: 'padding',
          isCurrentDay: false,
          i:i- paddingDays,
          date: '',
	        fecha: '',
          dia : i - paddingDays >= day && nav === 0 ? true : false,
          weekend : dateFake.getDay(),
          validNavCero: false,
          validNavUno: false,
        });
      }
    }

    setDays(daysArr);
  }, [nav]);

  return {
    days,
    dateDisplay,
  };
};
