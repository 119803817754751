import React, { useState } from 'react';

export const NewEventModal = ({setClicked}) => {

  return(
    <>
      <div id="newEventModal">
        <h2 className="error">no es posible Agendar este día</h2>

        <button
          onClick={() => setClicked(false)}
          id="cancelButton">Cerrar</button>
      </div>

      //<div id="modalBackDrop"></div>
    </>
  );
};
