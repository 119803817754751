import React, { useState } from 'react';

const ValidacionModal = ({setValido}) => {

  return(
    <>
      <div id="newEventModal">
        <h2 className="error">Ingresa primero una fecha</h2>

        <button
          onClick={() => setValido(true)}
          id="cancelButton">Cerrar</button>
      </div>

      //<div id="modalBackDrop"></div>
    </>
  );
};

export default ValidacionModal;
