import React, { useState} from 'react';
import CalendarHeader from '../CalendarHeader/CalendarHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSquare} from '@fortawesome/free-solid-svg-icons';
import { Day } from '../Day';
import { NewEventModal } from '../NewEventModal';
import ValidacionModal  from '../NewEventModal/ValidacionModal';
import { useDate } from '../hooks/useDate';
import axios from 'axios';
import {Boton,BotonAtras, ContenedorBotonCentrado} from '../../Form/Formularios';
import "../../Calendario/style.css";

const App = ({navigation, setHoraCita, setFechaCita, fecha_cita, hora_cita}) => {
  const [nav, setNav] = useState(0);
  const [clicked, setClicked] = useState();
  const [api, setApi] = useState([]);
  const [valido, setValido] = useState(false);
  const [clickValido, setclickValido] = useState(true);

  const horas = (xd) => {
    var fecha = xd;
    var obj = {fecha};
    setFechaCita(xd);
    axios.post(`https://prueba.mlsc.mx/api/horas`,obj)
                .then(res => {
                    console.log(res);
                    console.log(res.data.data);
                    setApi(res.data.data);
            }).catch(error => {
    })
  }


  const { days, dateDisplay } = useDate(nav);

  const handleSelect = (e) => {
    setHoraCita(e.target.value)
  }

  const handleButton = () =>{

      if(valido === true) {
          navigation.next();
          setValido(false);
      }else{
        setclickValido(false);
      }

  }

  return(
    <div className="body">
      <div id="container">
        <CalendarHeader
          dateDisplay={dateDisplay}
          nav={nav}
          onNext={() => setNav(nav + 1)}
          onBack={() => setNav(nav - 1)}
        />

        <div id="weekdays">
          <div>Domingo</div>
          <div>Lunes</div>
          <div>Martes</div>
          <div>Miércoles</div>
          <div>Jueves</div>
          <div>Viernes</div>
          <div>Sábado</div>
        </div>

        <div id="calendar">
          {days.map((d, index) => (
            nav === 0 ?
            <Day
              key={index}
              day={d}
              i={d.i}
              disabled={d.dia}
              weekend={d.weekend}
              valid={d.validNavCero}
              green={d.value !== 'padding' && d.dia !== false && d.weekend !==6 && d.weekend !== 0 && d.validNavCero!==true && d.value !== d.valuetwo && index >= d.isCurrentDay ? "green": ""}
              onClick={() => {
                if (d.value !== 'padding' && d.dia !== false && d.weekend !==6 && d.weekend !== 0 && d.validNavCero!==true && d.value !== d.valuetwo && index >= d.isCurrentDay) {

                    console.log(d.isCurrentDay + " ---- " + index)
                    console.log(d.fecha);
                    console.log(d.dia);
                    console.log(api);
                    horas(d.fecha);
                    setValido(true);
                    setclickValido(true);

                }else if(d.value === 'padding'){
                  setValido(false);
                }else{
                  setClicked(true);
                  setValido(false);
                }
              }}
            />
            :
            <Day
              key={index}
              day={d}
              disabled={true}
              weekend={d.weekend}
              valid={d.validNavUno}
              green={d.value !== 'padding' && d.weekend !==6 && d.weekend !== 0 && d.validNavUno!==true? "green": ""}
              onClick={() => {
                if (d.value !== 'padding' && d.weekend !==6 && d.weekend !== 0 && d.validNavUno!==true) {
                  console.log(d.validNavUno)
                  //setClicked(d.date);

                    //console.log(d.fecha);
                    //console.log(d.dia);
                //console.log(api);
                //setFechaCita(d.fecha);
                horas(d.fecha);
                setValido(true);
                setclickValido(true);

              }else if(d.value === 'padding'){
                  setValido(false);
              }else{
                  setClicked(true);
                  setValido(false);
                }
              }}
            />
        ))}
      </div>
    </div>
    <div className="direction">
    <h2 className="informacion-cita">Consideraciones para agendar una cita</h2>
    <p className="reglas-dias"><FontAwesomeIcon icon={faSquare}className="reglas"/> Días que no puede Agendar.</p>
    <p className="reglas-texto">Sí hoy es Viernes, Sábado o Domingo, no puede agendar día hasta el martes.</p>
    <p className="reglas-texto">Tiene que agendar con un día de anticipación.</p>
    <p className="reglas-texto">Sí Agenda de Lunes a Jueves, puede Agendar el día viernes.</p>
    <p className="reglas-texto">No puede agendar fines de semana.</p>
    <p className="informacion-cita">Información de cita</p>
    {valido && <p className="fecha-cita">{fecha_cita}</p>}
  	{valido ? <select name="select" className="select" onClick={handleSelect}>
    <option key={20} defaultValue disabled>Seleccione una fecha</option>
    {api.length > 0 ? api.map((item,index) =>{
                return(
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              })
    : <option key="21">No hay horarios Disponibles</option>}
    </select> : ''}
        {clicked && <NewEventModal setClicked={setClicked}/>}
        {!clickValido && !valido  && <ValidacionModal setValido={setclickValido}/>}
        <ContenedorBotonCentrado>
        <BotonAtras onClick={() => navigation.previous()}>Atras</BotonAtras>
        <Boton onClick={handleButton}>Siguiente</Boton>
        </ContenedorBotonCentrado>
        </div>
  </div>
  );
};


export default App;
