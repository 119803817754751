import React, {useState} from 'react';
import {Formulario,Boton, ContenedorBotonCentrado} from '../../../Form/Formularios';
import Input from '../../../Input/Input';
import ValidacionContacto from '../../../Calendario/NewEventModal/ValidacionContacto';

const Contacto = ({navigation, nombre_persona, setNombrePersona, numero_persona, setNumeroPersona, correo_persona, setCorreoPersona}) => {


    const [valido, setValido] = useState(false);

    const expresiones = {
        nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        telefono: /^\d{10,12}$/ // 10 a 12 numeros.
    }


    const handleButton = () =>{

        if(nombre_persona.valido === 'true' && numero_persona.valido === 'true' && correo_persona.valido === 'true'){
            navigation.next();
            setValido(false);

        }else{
            setValido(true);
        }

    }

    return (
        <Formulario>
            <Input
            tipo="text"
            label="Nombre Completo"
            placeholder="juanito"
            name="nombre_persona"
            leyendaError="El nombre puede contener solo letras."
            expresionRegular={expresiones.nombre}
            estado={nombre_persona}
            setEstado={setNombrePersona}
            />
            <Input
            tipo="email"
            label="Correo Electrónico"
            placeholder="juanito@correo.com"
            name="correo_persona"
            leyendaError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo."
            expresionRegular={expresiones.correo}
            estado={correo_persona}
            setEstado={setCorreoPersona}
            />
            <Input
            tipo="text"
            label="Telefono"
            placeholder="34343434"
            name="numero_persona"
            leyendaError="El telefono solo puede contener 10 minimo digitos y hasta 12 digitos."
            expresionRegular={expresiones.telefono}
            estado={numero_persona}
            setEstado={setNumeroPersona}
            />
            <ContenedorBotonCentrado>
            <Boton onClick={() => handleButton()}>Siguiente</Boton>
            {valido && <ValidacionContacto setValido={setValido}/>}
            </ContenedorBotonCentrado>

        </Formulario>
    );
};

export default Contacto;
