import React from 'react';

const Consultoria = ({setClick, setTipo}) => {

    const handleTipo = (a,b) => {
        setClick(a);
        setTipo(b);
    }

    return (
        <div className="consultoria-container">
            <div className="title-block animate" data-animate="fadeInUp">
                    <span>Consultoria en linea</span>
                    <h2>Agende una Asesoria en linea</h2>
            </div>
            <div className="consul">
                <div className="card animate" data-animate="fadeInUp">
                    <div className="card-image"></div>
                        <div className="card-text">
                            <h2>Legal</h2>
                            <p>Consulta tu proyecto con nuestro equipo de abogados
                            <br/>
                            <b>De Forma Gratuita</b>
                        </p>
                        </div>
                    <button onClick={() => handleTipo(true, true)} className="btn-consultoria">
                    LEGAL
                    </button>
                </div>
                <div className="card animate" data-animate="fadeInUp">
                    <div className="card-image"></div>
                        <div className="card-text">
                            <h2>Contable</h2>
                        <p>Consulta tu proyecto con nuestro equipo de abogados
                            <br/>
                            <b>De Forma Gratuita</b>
                        </p>
                        </div>
                    <button onClick={() => handleTipo(true, false)} className="btn-consultoria">
                    Contable
                    </button>
                </div>
            </div>
            <div className="scroll-down next-section animate celular"><img src="images/mouse-scroll.png" alt=""/><span>Desliza Hacia Abajo ↓</span></div>
        </div>

    );
};

export default Consultoria;
