import React from 'react';
import "./Spinner.css";
const Spinner = () => {
    return (
        <>
        <div className="spinner-container">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div className="spinner-modalbackdrop"></div>
        </>
    );
};

export default Spinner;